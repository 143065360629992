// Copyright 2018 Google LLC
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

/**
 * @fileoverview Synchronizes the quest tasks across the database and the
 * ui.
 */

export default class Quest {
  constructor (database, ui) {
    this.database = database
    this.ui = ui
  }
  loadTasks () {
    this.database.onTaskChanged((key, val) => {
      this.taskChanged_(key, val)
    })
  }
  taskChanged_ (key, val) {
    this.ui.setMetadataForQuestTaskKey(key, val)
  }
  async submitFlag (task, flag) {
    await this.database.submitFlag(task, flag)
    this.ui.setQuestTaskSolved(task, flag)
  }
}
