/**
 * @fileoverview Initializes the scoreboard.
 */

/** @define {string} API Key for CTF scoreboard */
const API_KEY = 'AIzaSyAFnYXvU__X6pBBWvzfvcKUkyidaNTicCw'
/** @define {string} Auth Domain for CTF scoreboard */
const AUTH_DOMAIN = 'cyberdefender-38fe1.firebaseapp.com'
/** @define {string} Database URL for CTF scoreboard */
const DATABASE_URL = 'https://cyberdefender-38fe1-default-rtdb.firebaseio.com'
/** @define {string} Project ID for CTF scoreboard */
const PROJECT_ID = 'cyberdefender-38fe1'
/** @define {string} Storage Bucket for CTF scoreboard */
const STORAGE_BUCKET = 'cyberdefender-38fe1.appspot.com'
const MESSAGE_SENDER_ID = '554171439653'
const APP_ID = '1:554171439653:web:6b4d409bcb26167b2b3dcc'

import { initializeApp } from '../_snowpack/pkg/firebase/app.js'
import Scoreboard from './scoreboard.js'

initializeApp({
  apiKey: API_KEY,
  authDomain: AUTH_DOMAIN,
  databaseURL: DATABASE_URL,
  projectId: PROJECT_ID,
  storageBucket: STORAGE_BUCKET,
  messagingSenderId: MESSAGE_SENDER_ID,
  appId: APP_ID
})

new Scoreboard().init()
